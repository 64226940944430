/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import tw, { css, theme } from "twin.macro";
import { graphql } from "gatsby";
import { Button, Footer, Go, Grid, Layout } from "~components";
import { useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const ThanksPage = ({ data, location }) => {
  // ==========================================================================
  // context / ref / state

  const { colours, colourTransitionCSS } = useTheme();

  // ==========================================================================
  // variables

  // const { sanityThanksPage, allSanityArticle } = data;

  // ===========================================================================
  // lifecycle

  // useEffect(() => {
  //
  // }, []);

  // ==========================================================================
  // render

  return (
    <>
      <Layout>
        <div
          css={[
            css`
              ${colourTransitionCSS};
            `
          ]}
        >
          <section
            css={[
              css`
                background: ${colours.background};
                color: ${colours.foreground};
                padding-top: 56px;

                ${MEDIA_QUERIES?.desktop} {
                  padding-top: 90px;
                }
              `,
              tw`w-full h-screen relative block`
            ]}
          >
            <Grid
              node="article"
              _css={[tw`h-full items-center justify-center`]}
            >
              <div
                css={[
                  tw`col-span-4 col-start-5 relative pt-8 px-6 pb-6 rounded-xl bg-green-light text-green-dark text-center`
                ]}
              >
                <h1
                  css={[
                    css`
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                      animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1)
                        appear-up forwards;
                      animation-delay: 0.25s;
                    `,
                    tw`mb-6 font-body text-h4-md`
                  ]}
                >
                  {/* {sanityThanksPage?.heading || `Welcome to The WIP!`} */}
                  Thank you for your purchase
                </h1>

                <p
                  css={[
                    css`
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                      animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1)
                        appear-up forwards;
                      animation-delay: 0.5s;
                    `,
                    tw`mb-8 font-body text-b2-md`
                  ]}
                >
                  {/* {sanityThanksPage?.body} */}
                  Your tokens will appear in your account shortly.
                </p>

                <div
                  css={[tw`w-full relative flex items-center justify-center`]}
                >
                  <Go
                    to="/app/profile"
                    _css={[
                      css`
                        width: calc(100% - 4rem);
                      `,
                      tw`relative block mx-auto`
                    ]}
                  >
                    <Button
                      _css={[
                        css`
                          padding: 0.75vw 0 !important;

                          span {
                            color: white;
                          }
                        `,
                        tw`w-full relative block`
                      ]}
                      color="green-dark"
                      text="Back to account"
                    />
                  </Go>
                </div>
              </div>
            </Grid>
          </section>
        </div>
      </Layout>

      <Footer />
    </>
  );
};

export default ThanksPage;

// export const query = graphql`
//   query ThanksPage {
//     sanityThanksPage {
//       body
//       heading
//     }

//     allSanityArticle {
//       edges {
//         node {
//           _id

//           availability
//           title
//           excerpt
//           readTime

//           slug {
//             current
//           }

//           theme {
//             backgroundColour {
//               hex
//             }
//             foregroundColour {
//               hex
//             }
//             buttonColour
//           }

//           category {
//             name
//           }

//           type {
//             title
//           }

//           cardImage {
//             asset {
//               gatsbyImageData(width: 1200, placeholder: NONE)
//             }
//           }
//         }
//       }
//     }
//   }
// `;
